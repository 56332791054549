import React from "react"
import { Link } from "gatsby"
import DesktopNav from "./desktopnav.js"
import MobileNav from "./mobilenav.js"

import "./header.css"
import accesSOSLogo from "../images/accesSOS-logo.svg"
class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      open: false,
      width: 0
    }
  }

  // CHECK CONSOLE - some warning messages
  openMenu = () => {
    this.setState({
      open: true,
    })
  }

  closeMenu = () => {
    this.setState({
      open: false,
    })
  }
  
  //sidebarToggler  ={this.props.sidebarToggler}

  render() {
    return (
        <header className="header-container">
          <div className="desktop-header">
            <div>
              <div openMenu={this.openMenu}>
                <DesktopNav />
              </div>
              <Link to="/#home">
                <img id='main-logo' src={accesSOSLogo}/>
              </Link>
            </div>
            <div id="nav-links">
              <Link
                to="/#our-story"
                activeStyle={{ color: "#939292" }}
                className="desktop-link"
              >
                About
              </Link>
              <Link
                to="/how-it-works"
                activeStyle={{ color: "#939292" }}
                className="desktop-link"
              >
                Learn
              </Link>
              <Link
                to="/press"
                activeStyle={{ color: "#939292" }}
                className="desktop-link"
              >
                Press
              </Link>
              

              <Link
                to="/faq"
                activeStyle={{ color: "#939292" }}
                className="desktop-link"
              >
                Support
              </Link>
              <a
                href="https://donorbox.org/911textapp-nationwide"
                target="_blank"
                rel="noreferrer"
              >
                <button className="desktop-donate">Donate</button>
              </a>
            </div>
            
          </div>
          <div className="mobile-header">
            <div>
              <Link to="/#home">
                <img id='main-logo' src={accesSOSLogo}/>
              </Link>
            </div>
            
            <div closeMenu={this.closeMenu}>
              <MobileNav />
            </div>
          </div>
        </header>
      // </div>
    )
  }
}

export default Header
