import React from "react"
import { Fade as Hamburger } from "hamburger-react"
import { Link } from "gatsby"

import "./mobilenav.css"

class MobileNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  toggle = toggled => {
    this.setState({
      open: toggled,
    })

    /* Prevent scrolling when menu open */
    if (toggled) {
      document.body.parentElement.style.overflow = "hidden"
    } else {
      document.body.parentElement.style.overflow = ""
    }
  }

  closeMenu = () => {
    this.setState({
      open: false,
    })
    document.body.parentElement.style.overflow = ""
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Hamburger
          color="#df4154"
          size={28}
          label="Show menu"
          toggled={this.state.open}
          onToggle={this.toggle}
        />
        {
          this.state.open && (
            // <div className={`menu ${this.state.open ? "open" : ""}`}>
            <div className="mobile-hamburgerContainer">
              <Link to="/#our-story" onClick={this.closeMenu}>
                {" "}
                Our Story{" "}
              </Link>
              <hr />
              <Link to="/#supporters" onClick={this.closeMenu}>
                {" "}
                Supporters{" "}
              </Link>
              <hr />
              <Link to="/how-it-works" onClick={this.closeMenu}>
                {" "}
                How It Works{" "}
              </Link>
              <hr />
              <Link to="/faq" onClick={this.closeMenu}>
                {" "}
                FAQs{" "}
              </Link>
              <hr />
              <Link to="/resources_page" onClick={this.closeMenu}>
                {" "}
                Resources{" "}
              </Link>
              <hr />
              <Link
                to="https://accessos.notion.site/Team-accesSOS-Public-Directory-68a3d83542d14bbbafc6d156a665c86b"
                onClick={this.closeMenu}
              >
                {" "}
                Meet the Team{" "}
              </Link>
              <hr />
              <Link
                to="https://www.notion.so/accessos/Job-Board-external-4cf4579f919a42228d1d6818b196dadf"
                onClick={this.closeMenu}
              >
                {" "}
                Careers{" "}
              </Link>
              <hr />
              <Link
                to="https://accessos.notion.site/Job-Board-external-4cf4579f919a42228d1d6818b196dadf"
                onClick={this.closeMenu}
              >
                {" "}
                Volunteer{" "}
              </Link>
              <hr />
              <Link to="/partner" onClick={this.closeMenu}>
                {" "}
                Partner{" "}
              </Link>
              <hr />
              <Link to="/press" onClick={this.closeMenu}>
                {" "}
                In the News{" "}
              </Link>
              <hr />
              <Link
                to="https://www.facebook.com/groups/access911"
                onClick={this.closeMenu}
              >
                {" "}
                Advocacy{" "}
              </Link>
              <hr />
              <Link to="/contact" onClick={this.closeMenu}>
                {" "}
                Contact Us{" "}
              </Link>
              <hr />
              <a
                href="https://donorbox.org/911textapp-nationwide"
                className="mobile-donate"
              >
                {" "}
                Donate{" "}
              </a>
              <hr />
            </div>
          )
          // </div>
          //our story supporters  how it works faqs resources get involved donate contact us
        }
      </div>
    )
  }
}

export default MobileNav
