import React from "react"
import { Link } from "gatsby"
import "./footer.css"
import appDL from "../images/appstore-download.svg"
import googleplay from "../images/googleplay.svg"
import accesSOSLogo from "../images/accesSOS-logo.svg"
import facebook from "../images/illustrations/Facebook.svg"
import instagram from "../images/illustrations/Instagram.svg"
import linkedin from "../images/illustrations/LinkedIn.svg"
import twitter from "../images/illustrations/X.svg"
import youtube from "../images/illustrations/YouTube.svg"

class Footer extends React.Component {
  constructor() {
    super()
    this.state = {
      email: "",
      submitted: false,
      invalid: false,
    }
    this.handleChange = this.handleChange.bind(this) /* FOR EMAIL CHECK */
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleSubmit = event => {
    if (!this.state.email.match(/([a-zA-Z0-9.\-_]+)@([a-z]+).([a-z]+)/)) {
      this.setState({
        invalid: true,
        submitted: false,
      })
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({ "form-name": "Subscribed", ...this.state }),
      })
        // .then(() => alert("Success!"))
        .catch(error => alert(error))

      // event.preventDefault();
      this.setState({
        email: "",
        submitted: true,
        invalid: false,
      })
    }
    event.preventDefault()
  }

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <div className="footer-container">
        <div>
          <div className="logo-container">
            <img src={accesSOSLogo}/>
            {/* Sign up container  */}
            <h1> Sign Up for Updates </h1>
            <div className="footer-subscribe">
              <form
                onSubmit={this.handleSubmit}
                name="Subscribed"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                netlify="true"
              >
                <div className="footer-subscribeContainer">
                  {/* <input type="email" id="email" name="email" onChange={this.handleChange} placeholder="example@email.com" value={this.state.email} required/> */}
                  <input
                    type="text"
                    id="email"
                    name="email"
                    onChange={this.handleChange}
                    placeholder="example@email.com"
                    value={this.state.email}
                    required
                  />
                  <button onClick={this.submitForm} className="button">
                    {" "}
                    Subscribe{" "}
                  </button>
                </div>
              </form>
              {this.state.submitted && (
                <div className="footer-submitSuccess">
                  Thank you for subscribing!
                </div>
              )}
              {this.state.invalid && (
                <div className="footer-submitFail">
                  Enter a valid email address
                </div>
              )}
            </div>
          </div>
          
          <div className="footer-linksContainer">
            <div class="footer-item">
              <h3> accesSOS </h3>
              <div className="footer-links">
                {/* <div className="footer-flex">
                                    <Link to="/"> Team Access </Link>
                                    <span role="img" aria-label="I love you">🤟</span>
                                </div> */}
                <a
                  href="https://accessos.notion.site/Team-accesSOS-Public-Directory-68a3d83542d14bbbafc6d156a665c86b"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Meet the Team {" "}
                </a>
                <Link to="/contact"> Contact Us </Link>
              </div>
            </div>
            <div class="footer-item">
              <h3> Learn </h3>
              <div className="footer-links">
                <a
                  href="https://text911.info/ "
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Text to 911 Map{" "}
                </a>
                <Link to="/faq"> FAQs </Link>
                <Link to="/resources_page"> Resources </Link>
                <a
                  href="https://www.notion.so/accessos/Media-Kit-45ff75414e2f442099f7008801689572"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Media Kit{" "}
                </a>
              </div>
            </div>
            <div class="footer-item">
              <h3> Support </h3>
              <div className="footer-links">
                <a
                  href="https://donorbox.org/911textapp-nationwide"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Donate{" "}
                </a>
                <Link to="/partner"> Partner </Link>
                <a
                  href="https://accessos.notion.site/Job-Board-external-4cf4579f919a42228d1d6818b196dadf"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Join Us{" "}
                </a>
                <a
                  href="https://www.facebook.com/groups/access911"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Advocacy{" "}
                </a>
              </div>
            </div>
            <div className="footer-item">
              <h3> Download </h3>
              <div className="downloads">
                <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/accessos/id1578050242">
                  <img src={appDL} alt='Download from App Store'/>
                </a>
                <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.AccesSOS.AccesSOSApp&hl=en_US&gl=US">
                  <img src={googleplay} alt='Download from App Store'/>
                </a>
              </div>
            </div>
          </div>

          <div className="footer-end">
            <div className="copyright">
              <h4>
                {" "}
                © {new Date().getFullYear()} accesSOS. All rights reserved. <br/> accesSOS, EIN 84-2489856, is
                a registered nonprofit 501(c)(3) organization.{" "}
              </h4>
              <h4>
                <a target="_blank" rel="noreferrer" href="https://accessos.notion.site/Terms-of-Use-5588e697bef34b22b9936e2273319230">
                  Terms of Use
                </a>
                {" "}|{" "}
                <a target="_blank" rel="noreferrer" href="https://accessos.notion.site/Privacy-Policy-7fecd63361ad44c68ec1cc64139a374b">
                  Privacy Policy
                </a>
              </h4>  
            </div>
            <div className="socials"> 
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/911access/">
                <img src={instagram} alt="Link to Instagram"/>
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/accessos/">
                <img src={linkedin} alt="Link to LinkedIn"/>
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCzeRKkzcRAmGE_3nNAmInoA">
                <img src={youtube} alt="Link to YouTube"/>
              </a>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/911access">
                <img src={twitter} alt ="Link to Twitter"/>
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/911access">
                <img src={facebook} alt="Link to Facebook"/>
              </a>                
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
